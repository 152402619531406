<template>
  <v-app>
    <v-content class="white">
      <header-info />
      <section class="info__dishe">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-img
              :height="imgMenu"
              :src="image_url"
              :lazy-src="image_url"
              class="white--text"
              width="100%"
              :class="{'ma-3': $vuetify.breakpoint.mdAndUp}"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" :class="{'pa-3': $vuetify.breakpoint.mdAndUp}">
            <v-row no-gutters>
              <v-col cols="12" class="text-center pt-5 pb-3 px-5">
                <span class="title_dishe" v-text="name_menu"></span>
              </v-col>
              <v-col cols="12" class="text-center px-8 py-0">
                <p class="description_dishe" v-html="description_menu"></p>
              </v-col>
            </v-row>

            <v-row v-if="this.detailsMenu.show_price" justify="center" no-gutters>
              <v-col cols="12" class="text-center pt-3 pb-1">
                <p class="title_price" v-text="$t('default.plato.precio')"></p>
              </v-col>
              <v-col cols="12" class="text-center">
                <template
                  v-for="(p,key) in prices">
                  <v-chip-group :key="key" mandatory active-class="primary--text" >
                    <div  style="margin: auto">
                    <template v-for="(detail, idx) in p.prices"
                      >
                      <v-chip :key="idx"
                        label large>
                        {{ p.name }}<br>
                        {{ detail.amount }} {{ coinSymbol[detail.coin] }}
                      </v-chip>

                    </template>
                    </div>
                  </v-chip-group>

                </template>


                <!-- <addToCard :item="detailsMenu" :type="'menu'"></addToCard> -->
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row justify="center" no-gutters>
          <v-col cols="10" class="text-center pt-3" >
            <base-divider dense color="" max-width="100%" space="0" />
          </v-col>
        </v-row>
        <v-container fluid class="px-1 pt-0">
          <v-row class="mx-0">
            <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '8'" :class="$vuetify.breakpoint.mdAndUp ? 'pr-6' : 'pb-4 pt-0'">
              <!-- IMAGENES PLATOS -->
              <v-row class="box-letter">
                <v-col cols="6" sm="4" md="4" lg="4" class="pt-1" v-for="(dishe, i) in menu_dihes" :key="i" >
                  <card-dishe-letter v-if="dishe.visibility" :item="dishe" type="dishe" :allergen="true"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-container>

      </section>

    </v-content>
    <v-overlay :value="overlay" :opacity="0.99" :z-index="10" color="white" class="text-center">
      <v-img
        contain
        max-width="120"
        :src="require('@/assets/Todo-carta-logo.png')"
      ></v-img>
      <v-progress-linear
          color="blue-grey lighten-3"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>
    </v-overlay>
  </v-app>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import HeaderInfo from './components/HeaderInfo'
  import CardDisheLetter from './components/CardDisheLetter'
  import coinSymbol from "../../mixins/coinSymbol"
  // import addToCard from './components/AddToCard'
  const os = require('os');
  export default {
    name: 'BaseView',
    components: { HeaderInfo, CardDisheLetter},
    data: () => ({
      detailsMenu: [],
      menu_dihes: [],
      url_img: process.env.VUE_APP_IMG_URL_API,
      restaurant: []
    }),
    head() {
      return{
        title: this.name_restaurant,
        link: [
          { rel: 'icon', type: 'image/x-icon', href: this.logo }
        ],
        meta: [
          { name: 'description', content: 'Todo cartas online es un application dirigida a restaurantes la cual contiene un módulo de carta electrónica donde se puede consultar la carta desde cualquier dispositivo, tus clientes pueden ver la carta de tu restaurante desde sus propios dispositivos móviles. No hace falta que los clientes instalen nada en su dispositivo, solo leerán un código QR y ya tienen disponible la carta.'},
          { property: 'og:title', content: this.name_restaurant + ' - Todo Cartas Online'},
          { property: 'og:site_name', content: 'Todo Cartas Online'},
          { property: 'og:description', content: 'Restaurante ' + this.name_restaurant + ' en Todo Cartas Online'},
          {property: 'og:type', content: 'web'},
          {property: 'og:url', content: 'https://' + os.hostname() + '/r/' + this.restaurant.url},
          {property: 'og:image', content: this.logo }
        ]
      }
    },
    created(){
      this.getMenu();
    },
    methods:{
      async getMenu(){
        const url = this.$route.params.landing ? this.$route.params.landing : '';
        const id = this.$route.params.id ? this.$route.params.id : '';
        this.$store.commit('SET_OVERLAY', true);
        try{
          const {data} = await this.$api.get(`api/restaurant/${url}/menus/${id}`);
          const color = JSON.parse(data.data.restaurant.restaurant_setup.colors);
          this.$store.commit('landing/SET_CONFIG', data.data.restaurant);
          this.$store.commit('landing/SET_COLOR', color.default);
          this.$vuetify.theme.themes.light.landing_primary = color.default;
          this.detailsMenu = data.data.menu;
          this.restaurant = data.data.restaurant.restaurant_setup;
          this.menu_dihes = data.data.menu.dishes;
        }
        catch(e){
          if(e.response.data.error_code == '400'){
            this.$store.commit('SET_OVERLAY', false);
            this.$router.push({name: '404', query: {msj: e.response.data.message}})
          }
          else{
            this.$store.commit('SET_OVERLAY', false);
            this.$router.push({name: '404'})
          }
        }
        finally {
          this.$store.commit('SET_OVERLAY', false);
        }
      }
    },
    computed: {
      ...mapState(['overlay']),
      ...mapGetters({
        language: 'language',
        logo: 'landing/logo',
        image_header: 'landing/header',
        name_restaurant: 'landing/name',
        address: 'landing/address',
        phone: 'landing/phone',
        email: 'landing/email',
        lat: 'landing/lat',
        lng: 'landing/lng',
        url_allergen: 'landing/url_allergen'
      }),
      imgMenu(){
        const height = this.$vuetify.breakpoint.mdAndUp ? '60vh' : '100%'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      name_menu(){
        if(this.detailsMenu.name)
          return this.detailsMenu.name[this.$store.getters.language] ? this.detailsMenu.name[this.$store.getters.language] : this.detailsMenu.name.es;
        return '';
      },
      description_menu(){
        if(this.detailsMenu.description)
          return this.detailsMenu.description[this.$store.getters.language] ? this.detailsMenu.description[this.$store.getters.language] : this.detailsMenu.description.es;
        return '';
      },
      image_url(){
        return this.detailsMenu.image_menu ? `${this.url_img}images/menu/${this.detailsMenu.image_menu}` : '';
      },
      prices() {
        if(this.detailsMenu.price === undefined) return []
        let price = JSON.parse(this.detailsMenu.price);
        return price;
      },
      coinSymbol() {
        return coinSymbol
      }
    },
  }
</script>
<style lang="sass">
  .icon-left
    margin-left: -4px
    margin-right: 8px

  .info__dishe
    margin-bottom: 2rem
    & .title_dishe
      padding: 15px 0px
      font-size: 1.2rem
      letter-spacing: 1px
      font-weight: 600
    & .description_dishe
      color: #666666
      text-align: center
      margin-bottom: 0px !important
    & .price_dishe
      font-weight: 600
      font-size: 1.4rem
    & .title_price
      color: #7F7F7F
      margin-bottom: 0px !important
      font-weight: 500
      font-size: 1.4rem


</style>
